


import Component from 'vue-class-component';
import {Vue} from 'vue-property-decorator';
import {Getter} from 'vuex-class';
import statisticsApi from '@/_modules/statistics/api/statistics.api';
import {TContact} from '@/_types/contact.type';
import {TDiscoverySessionOnlineCheck} from '@/_types/discovery/discovery-session-online-check.type';

@Component({
  name: 'presence-confirmation',
})
export default class PresenceConfirmation extends Vue {
  @Getter('promoProgramStore/getPresenceQuestionData') getPresenceQuestionData: TDiscoverySessionOnlineCheck;
  @Getter('promoPageStore/contact') contact: TContact
  public get eventId(): number {
    return (this.$route.params.eventId && parseInt(this.$route.params.eventId, 10)) || null;
  }

  public async sendStat(): Promise<void> {

    const eventId = this.eventId;
    const actionName = this.getPresenceQuestionData.type;
    const data = {
      contactId: this.contact.id,
      contact: this.contact,
      programSessionId: this.getPresenceQuestionData.programSessionId,
      type: 'WEB'
    };

    await statisticsApi.putStat({ eventId, actionName, data });
    await this.$store.dispatch('promoProgramStore/setPresenceQuestionData', null);
  }
}
