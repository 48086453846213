


import { Vue, Component, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { TMeetingRoomState } from '@/_modules/meeting-rooms/types/meeting-room-state.type';
import { TUser } from '@/_types/user.type';
import { TContact } from '@/_types/contact.type';
import MeetingRoom from '@/_modules/meeting-rooms/components/meeting-room/meeting-room.vue';
import BroadcastRoom from '@/_modules/meeting-rooms/components/broadcast-room/broadcast-room.vue';
import CompanyRoom from '@/_modules/meeting-rooms/components/company-room/company-room.vue';
import EventChat from '@/_modules/event-chat/components/event-chat/event-chat.vue';
import StreamPlayer from '@/_components/stream-player/stream-player.vue';
import FixedDraggable from '@/_components/fixed-draggable/fixed-draggable.vue';
import MeetingsHelper from '@/_helpers/meetings.helper';
import { TMeetingRoomConfig } from '@/_modules/meeting-rooms/types/meeting-room-config.type';
import { MeetingRoomType } from '@/_modules/meeting-rooms/types/meeting-room-type.enum';
import { TMediaItem, TOverlookMediaItem } from '@/_modules/events/types/media-item.type';
import LiveMediaBlock from '@/_modules/promo/components/live-media-block/live-media-block.vue';
import { TLivePage } from '@/_types/promo-page/live-page.type';
import { NavigationGuardNext, Route } from 'vue-router';
import broadcastsService from '@/_services/broadcasts.service';
import SimplePopup from '@/_modules/controls/components/simple-popup/simple-popup.vue';
import PromoBroadcastSettings, { PromoBroadcastSettingsProgram } from '@/_modules/promo/components/promo-broadcast-settings/promo-broadcast-settings.vue';
import PromoBroadcastTimeCheck
  from '@/_modules/promo/components/promo-broadcast-time-check/promo-broadcast-time-check.vue';
import PromoChatroomTimeCheck
  from '@/_modules/promo/components/promo-chatroom-time-check/promo-chatroom-time-check.vue';
import MeetingCancelConfirmation
  from '@/_modules/promo/components/meeting-cancel-confirmation/meeting-cancel-confirmation.vue';
import MeetingDeclineConfirmation
  from '@/_modules/promo/components/meeting-decline-confirmation/meeting-decline-confirmation.vue';
import { TEvent } from '@/_types/event.type';
import { BroadcastType } from '@/_types/broadcasts/broadcast-type.enum';
import EmbedCodeForm from '@/_modules/events/components/embed-code-form/embed-code-form.vue';
import MediaBlockItem from '@/_modules/events/components/media-block-item/media-block-item.vue';
import PromoNotification from '@/_modules/promo/components/promo-notification/promo-notification.vue';
import { TNotification } from '@/_modules/promo/types/notification.type';
import { POPUP_NOTIFICATION_ANIMATION_TIME } from '@/_modules/promo/store/notifications.store';
import eventDiscoveryService from '@/_services/event-discovery.service';
import { TCompanyVideoChatState } from '@/_modules/meeting-rooms/types/company-video-chat-state.type';
import PromoNotificationMeeting
  from '@/_modules/promo/components/promo-notification-meeting/promo-notification-meeting.vue';
import PromoNotificationNewsArticle
  from '@/_modules/promo/components/promo-notification-news-article/promo-notification-news-article.vue';
import { TDiscoveryWaitingMeetingNotificationResponse } from '@/_types/discovery/discovery-waiting-meeting-notification-response.type';
import { TDiscoveryNewsArticleNotificationResponse } from '@/_types/discovery/discovery-news-article-notification-response.type';
import RemoveCoworkerConfirmation
  from '@/_modules/promo/components/confirmations/remove-coworker-confirmation/remove-coworker-confirmation.vue';
import PresenceConfirmation
  from '@/_modules/promo/components/confirmations/presence-confirmation/presence-confirmation.vue';
import { TConferenceRoom } from '@/_modules/promo/types/conference-room.type';
import ProgramMediaBlock from '@/_modules/promo-program/components/program-media-block/program-media-block.vue';
import { TDiscoverySessionOnlineCheck } from '@/_types/discovery/discovery-session-online-check.type';
import store from '@/store';
import { TConferenceProgram } from '@/_modules/promo/types/conference-program.type';
import UtilsHelper from '@/_helpers/utils.helper';
import OpenedContactsViewer from '@/_modules/contacts/components/opened-contacts-viewer/opened-contacts-viewer.vue';
// @ts-ignore
import Statistics from '@/services/statistics.js';
import CookiesHelper from '@/_helpers/cookies-helper';

const beforeRouteEnter: { to: Route; from: Route; next?: NavigationGuardNext } = { to: null, from: null, next: null };

// See https://class-component.vuejs.org/guide/additional-hooks.html
Component.registerHooks([
  'beforeRouteLeave',
]);

@Component({
  components: {
    MeetingRoom,
    BroadcastRoom,
    CompanyRoom,
    EventChat,
    StreamPlayer,
    FixedDraggable,
    MediaBlockItem,
    LiveMediaBlock,
    SimplePopup,
    PromoBroadcastSettings,
    PromoBroadcastTimeCheck,
    PromoChatroomTimeCheck,
    EmbedCodeForm,
    PromoNotification,
    PromoNotificationMeeting,
    PromoNotificationNewsArticle,
    MeetingCancelConfirmation,
    MeetingDeclineConfirmation,
    RemoveCoworkerConfirmation,
    ProgramMediaBlock,
    PresenceConfirmation,
    OpenedContactsViewer,
  },
})
export default class Event extends Vue {

  @Getter('_eventStore/event') readonly event: TEvent;
  @Getter('authStore/isAuthenticated') readonly isAuthenticated: boolean;
  @Getter('_userStore/user') readonly user: TUser;
  @Getter('promoPageStore/contact') readonly contact: TContact;
  @Getter('meetingRoomsStore/broadcasts') readonly broadcasts: TMeetingRoomState[];
  @Getter('meetingRoomsStore/meetings') readonly meetings: TMeetingRoomState[];
  @Getter('meetingRoomsStore/companyVideoChats') readonly companyVideoChats: TMeetingRoomState[];
  @Getter('promoStore/unpinnedMediaItems') readonly unpinnedMediaItems: TMediaItem[];
  @Getter('promoProgramStore/unpinnedMediaItems') readonly programUnpinnedMediaItems: TMediaItem[];
  @Getter('promoProgramStore/getOverlookMediaItems') readonly getOverlookMediaItems: (programId: number) => TOverlookMediaItem[];
  @Getter('promoProgramStore/conferenceRooms') readonly conferenceRooms: TConferenceRoom[];
  @Getter('_eventStore/obsSettingsDialogConfig') readonly obsSettingsDialogConfig: TMeetingRoomConfig;
  @Getter('_eventStore/streamYardSettingsDialogConfig') readonly streamYardSettingsDialogConfig: TMeetingRoomConfig;
  @Getter('_eventStore/zoomSettingsDialogConfig') readonly zoomSettingsDialogConfig: TMeetingRoomConfig;
  @Getter('_eventStore/embedCodeDialogConfig') readonly embedCodeDialogConfig: TMeetingRoomConfig;
  @Getter('_eventStore/isBroadcastTimeCheckDialogVisible') readonly isBroadcastTimeCheckDialogVisible: boolean;
  @Getter('_eventStore/isChatRoomTimeCheckDialogVisible') readonly isChatRoomTimeCheckDialogVisible: boolean;
  @Getter('notificationsStore/popupNotifications') readonly popupNotifications: TNotification[];
  @Getter('notificationsStore/waitingMeetingNotification') readonly waitingMeetingNotification: TDiscoveryWaitingMeetingNotificationResponse;
  @Getter('notificationsStore/newsArticleNotification') readonly newsArticleNotification: TDiscoveryNewsArticleNotificationResponse;
  @Getter('meetingRoomsStore/getCompanyVideoChatStateByExternalId') readonly getCompanyVideoChatStateByExternalId: (externalId: string) => TCompanyVideoChatState;
  @Getter('meetingsStore/isMeetingTooEarlyPopupVisible') readonly isMeetingTooEarlyPopupVisible: boolean;
  @Getter('meetingsStore/isMeetingCancelPopupVisible') readonly isMeetingCancelPopupVisible: boolean;
  @Getter('meetingsStore/isMeetingDeclinePopupVisible') readonly isMeetingDeclinePopupVisible: boolean;
  @Getter('promoPageStore/isRemoveCoworkerPopupVisible') readonly isRemoveCoworkerPopupVisible: boolean;
  @Getter('cabinetLobbyStore/livePageData') readonly livePageData: TLivePage;
  @Getter('cabinetLobbyStore/isIntroMediaUnpinned') readonly isIntroMediaUnpinned: boolean;
  @Getter('cabinetLobbyStore/isAgendaMediaUnpinned') readonly isAgendaMediaUnpinned: boolean;
  @Getter('promoProgramStore/getPresenceQuestionData') readonly presenceQuestion: TDiscoverySessionOnlineCheck;

  public readonly popupNotificationAnimationTime: number = POPUP_NOTIFICATION_ANIMATION_TIME / 1000;
  public readonly PromoBroadcastSettingsProgram: typeof PromoBroadcastSettingsProgram = PromoBroadcastSettingsProgram;
  public readonly BroadcastType: typeof BroadcastType = BroadcastType;

  public program: TConferenceProgram = null;

  private destroyed$: Subject<void> = new Subject<void>();
  private meetingInviteCheck$: Subject<void> = new Subject<void>();
  private presenceQuestionTimeout: number;

  public get eventId(): number {
    return (this.$route.params.eventId && parseInt(this.$route.params.eventId, 10)) || null;
  }

  public get contactId(): number {
    return (this.contact && this.contact.id) || null;
  }

  public get userId(): number {
    return (this.user && this.user.id) || null;
  }

  public get meetingInviteKey(): string {
    return this.$route.params.inviteKey;
  }

  public get overlookMediaItems(): TOverlookMediaItem[] {
    return (this.program && this.program.id && this.getOverlookMediaItems(this.program.id));
  }

  public get isShowPresenceQuestion(): boolean {
    return (this.presenceQuestion && this.presenceQuestion.isShowPresenceQuestion);
  }

  public get isEventAccessEnabled(): boolean {
    return this.event && this.event.is_enabled;
  }

  public get isEventCreator(): boolean {
    return this.event && this.event.personal && this.event.personal.is_creator;
  }

  public created(): void {
    this.meetingInviteCheck$.pipe(
      takeUntil(this.destroyed$),
      debounceTime(1000),
    ).subscribe(() => {
      this.meetingInviteCheck();
    });

    // this.$store.dispatch('notificationsStore/simulate');
  }

  public beforeDestroy(): void {
    this.meetingInviteCheck$.complete();
    broadcastsService.disableActiveBroadcastsCheck();
    this.configureEventDiscoveryService({ eventId: null, contactId: null });
    this.configureChatStore({ eventId: null, contactId: null });
    clearTimeout(this.presenceQuestionTimeout);
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public get hasAccess(): boolean {
    return (this.event && this.event.personal && this.event.personal.has_access) || false;
  }

  public onOBSSettingsDialogClose(): void {
    this.$store.dispatch('_eventStore/setObsSettingsDialogConfig', null);
  }

  public onStreamYardSettingsDialogClose(): void {
    this.$store.dispatch('_eventStore/setObsSettingsDialogConfig', null);
  }

  public onZoomSettingsDialogClose(): void {
    this.$store.dispatch('_eventStore/setZoomSettingsDialogConfig', null);
  }

  public onEmbedCodeDialogClose(): void {
    this.$store.dispatch('_eventStore/setEmbedCodeDialogConfig', null);
  }

  public onBroadcastTimeCheckDialogClose(): void {
    this.$store.dispatch('_eventStore/setIsBroadcastTimeCheckDialogVisible', false);
  }

  public onChatRoomTimeCheckDialogClose(): void {
    this.$store.dispatch('_eventStore/setIsChatRoomTimeCheckDialogVisible', false);
  }

  public beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext): void {
    if (to.params && to.params.forceAuthPageRoute) {
      next();
    } else if (to.name === 'auth-page' && !this.isAuthenticated) {
      this.$store.dispatch('authStore/setAuthPopupTargetRoute', to.params && to.params.redirect ? to.params.redirect : null);
      this.$store.dispatch('authStore/setShowStandaloneAuth', true);
    } else {
      next();
    }
  }

  @Watch('isAuthenticated', { immediate: true })
  private onIsAuthenticatedChange(): void {
    this.updateMeetingRoomsStore();
    this.configureEventDiscoveryService({ eventId: null, contactId: null });
    this.configureChatStore();
  }

  @Watch('contactId', { immediate: true })
  private onContactIdChange(): void {
    this.updateMeetingRoomsStore();
    this.configureEventDiscoveryService();
    this.configureChatStore();
  }

  public beforeRouteEnter(to: Route, from: Route, next: NavigationGuardNext): void {
    beforeRouteEnter.to = to;
    beforeRouteEnter.from = from;
    next();
  }

  @Watch('eventId', { immediate: true })
  private onEventIdChange(): void {
    const eventId = this.eventId;

    this.$store.dispatch('promoStore/clearPromoCompanies');
    this.$store.dispatch('_messageStore/setEventId', eventId);
    this.updateMeetingRoomsStore();
    this.updatePromoProgramStore();
    this.updateMeetingsStore();
    this.updateContactsStore();

    this.meetingInviteCheck$.next();
    if (eventId) {
      broadcastsService.enableActiveBroadcastsCheck(eventId);
    } else {
      broadcastsService.disableActiveBroadcastsCheck();
    }
    this.configureEventDiscoveryService();
    this.configureChatStore();
  }

  @Watch('meetingInviteKey', { immediate: true })
  private onMeetingInviteKeyChange(): void {
    this.meetingInviteCheck$.next();
  }

  @Watch('user', { immediate: true })
  private onUserChange(): void {
    this.meetingInviteCheck$.next();
    this.updatePromoProgramStore();
    this.updateMeetingsStore();
  }

  @Watch('contact', { immediate: true })
  private onContactChange(newContact: TContact, oldContact: TContact): void {
    this.meetingInviteCheck$.next();
    if (!oldContact && newContact) {
      this.updatePromoProgramStore();
    }
  }

  @Watch('hasAccess')
  private onHasAccessChange(): void {
    this.updatePromoProgramStore();
    this.meetingInviteCheck$.next();
  }

  @Watch('presenceQuestion')
  private onPresenceQuestionChange(): void {
    clearTimeout(this.presenceQuestionTimeout);
    this.presenceQuestionTimeout = window.setTimeout(() => {
      store.dispatch('promoProgramStore/setPresenceQuestionData', null);
      clearTimeout(this.presenceQuestionTimeout);
    }, 120000);
  }

  public async unpinActiveProgramStreams(): Promise<void> {
    if (
      this.programUnpinnedMediaItems.length
      || this.$route.name === 'promo-program-date-program'
    ) {
      return;
    }

    for (let roomIdx = 0; roomIdx < this.conferenceRooms.length; roomIdx++) {
      const programs = this.conferenceRooms[roomIdx].programs;
      for (let programIdx = 0; programIdx < programs.length; programIdx++) {

        const program = programs[programIdx];
        const mediaItem = programs[programIdx].mediaItems;

        if (this.$moment().isBetween(program.date_start, program.date_end) && mediaItem) {

          for (let mediaItemIdx = 0; mediaItemIdx < mediaItem.length; mediaItemIdx++) {
            this.program = programs[programIdx];
            const node = document.getElementById('program-media-item-' + mediaItem[mediaItemIdx].id);

            if (this.overlookMediaItems && this.overlookMediaItems.length) {
              if (this.overlookMediaItems.find((item) => item.id === mediaItem[mediaItemIdx].id)) {
                continue;
              }
            }

            if (node && (mediaItem[mediaItemIdx].type === 'broadcast')) {

              await this.$store.dispatch('promoProgramStore/setActiveMediaItem', {
                programId: program.id,
                id: mediaItem[mediaItemIdx].id,
              });

              await this.$store.dispatch('promoProgramStore/unpinMediaItem', {
                programId: program.id,
                id: mediaItem[mediaItemIdx].id,
              });

              this.$nextTick(() => {
                const container = document.getElementById('media-item-container-' + mediaItem[mediaItemIdx].id);

                if (container) {
                  container.append(node);
                }

              });
            }

          }

          break;
        }
      }
    }

    const unpinnedNodes: NodeListOf<any> = document.querySelectorAll('.fixed-draggable-company-media-item');

    if (unpinnedNodes) {
      unpinnedNodes.forEach((item, index) => {
        const offset = item.getBoundingClientRect();
        if (index === 0) {
        } else {
          item.style.transform = `translateX(-${(offset.width * index) + 20 * index}px`;
        }
      });
    }
  }

  // @Watch('unpinnedMediaItems', { immediate: true, deep: true })
  // private onUnpinnedMediaItemsChange(): void {
  //   console.log('onUnpinnedMediaItemsChange', this.unpinnedMediaItems);
  // }

  // @Watch('programUnpinnedMediaItems', { immediate: true, deep: true })
  // private onProgramUnpinnedMediaItemsChange(): void {
  //   console.log('onProgramUnpinnedMediaItemsChange', this.programUnpinnedMediaItems);
  // }

  @Watch('event', { immediate: true })
  private onEventChange(): void {
    this.setEventCookie();
  }

  private updateContactsStore(): void {
    this.$store.dispatch('contactsStore/setEventId', this.eventId);
  }

  private updateMeetingsStore(): void {
    const userId = (this.user && this.user.id) || null;
    const eventId = this.eventId;
    this.$store.dispatch('meetingsStore/setEventId', eventId);
    if (userId) {
      this.$store.dispatch('meetingsStore/requestUserMeetings', { userId });
    }
  }

  private async meetingInviteCheck(): Promise<void> {
    const meetingInviteKey = this.meetingInviteKey;
    const contactId = this.contactId;
    if (
      (this.$route.name !== 'meeting-invite' && this.$route.name !== 'company-video-chat-invite')
      || !contactId
      || !meetingInviteKey
    ) {
      return;
    }

    const eventId = this.eventId;
    const meetingInvite = MeetingsHelper.parseMeetingInviteKey(meetingInviteKey);
    if (!meetingInvite || !meetingInvite.type || meetingInvite.eventId !== eventId) {
      // TODO: show invalid invitation key info popup?
      return;
    }

    if (meetingInvite.type === MeetingRoomType.MEETING) {
      if (!meetingInvite.meetingId || !meetingInvite.meetingDate) {
        // TODO: show invalid invitation key info popup?
        return;
      }
      const meetingRoomConfig: TMeetingRoomConfig = {
        type: MeetingRoomType.MEETING,
        eventId: eventId,
        meetingId: meetingInvite.meetingId,
        contactId: contactId,
        meetingDate: meetingInvite.meetingDate,
      };
      this.$store.dispatch('meetingRoomsStore/join', meetingRoomConfig);
    }

    if (meetingInvite.type === MeetingRoomType.COMPANY) {
      if (!meetingInvite.moderatorContactId || !meetingInvite.externalId) {
        // TODO: show invalid invitation key info popup?
        return;
      }
      let videoChatState = this.getCompanyVideoChatStateByExternalId(meetingInvite.externalId);
      if (!videoChatState) {
        await new Promise<void>((resolve) => {
          const maxChecks = 6;
          let checkNumber = 0;
          const checkInterval = window.setInterval(() => {
            checkNumber++;
            videoChatState = this.getCompanyVideoChatStateByExternalId(meetingInvite.externalId);
            if (videoChatState) {
              window.clearInterval(checkInterval);
              resolve();
            }
            if (checkNumber >= maxChecks) {
              window.clearInterval(checkInterval);
              resolve();
            }
          }, 1000);
        });
      }

      if (!videoChatState || meetingInvite.moderatorContactId !== videoChatState.moderatorContactId) {
        // TODO: show something to user?
        return;
      }
      const meetingRoomConfig = {
        type: MeetingRoomType.COMPANY,
        eventId: eventId,
        contactId: contactId,
        moderatorContactId: meetingInvite.moderatorContactId,
        externalId: meetingInvite.externalId,
      };
      this.$store.dispatch('meetingRoomsStore/join', meetingRoomConfig);
    }
  }

  private updateMeetingRoomsStore(): void {
    const eventId = this.eventId;
    const isAuthenticated = this.isAuthenticated;
    const contactId = this.contactId;
    this.$store.dispatch('meetingRoomsStore/setEventId', (isAuthenticated && contactId && eventId) || null);
  }

  private updatePromoProgramStore(): void {
    if (this.eventId && this.user) {
      this.$store.dispatch('promoProgramStore/loadProgram', {
        eventId: this.eventId,
        acceptLanguage: process.env.VUE_APP_I18N_FALLBACK_LOCALE || Vue.localStorage.get('language') || 'en'
      });
    } else {
      this.$store.dispatch('promoProgramStore/reset');
    }
  }

  private configureEventDiscoveryService(config?: { eventId: number; contactId: number }): void {
    const isAuthenticated = this.isAuthenticated;
    const contactId = this.contactId;
    const eventId = this.eventId;
    eventDiscoveryService.configure(config || {
      eventId: eventId,
      contactId: (isAuthenticated && contactId) || null,
    });
  }

  private configureChatStore(config?: { eventId: number; contactId: number }): void {
    const isAuthenticated = this.isAuthenticated;
    const contactId = this.contactId;
    const eventId = this.eventId;
    this.$store.dispatch('chatStore/configure', config || {
      eventId: eventId,
      contactId: (isAuthenticated && contactId) || null,
    });
  }

  private closeMeetingTooEarlyPopup(): void {
    this.$store.dispatch('meetingsStore/setMeetingTooEarlyPopupVisible', false);
  }

  private closeMeetingCancelPopup(): void {
    this.$store.dispatch('meetingsStore/setMeetingCancelPopupVisible', false);
  }

  private closeMeetingDeclinePopup(): void {
    this.$store.dispatch('meetingsStore/setMeetingDeclinePopupVisible', false);
  }

  private closeRemoveCoworkerPopup(): void {
    this.$store.dispatch('promoPageStore/setRemoveCoworkerPopupVisible', false);
  }

  private setEventCookie(): void {
    const eventViewCookieName = 'event_view-' + this.eventId;
    const eventViewed = CookiesHelper.readCookie(eventViewCookieName);
    const curDate = new Date();
    const expire = new Date();
    expire.setUTCFullYear(curDate.getFullYear());
    expire.setUTCMonth(curDate.getMonth());
    expire.setUTCDate(curDate.getDate() + 1);
    expire.setUTCHours(0);
    expire.setUTCMinutes(0);
    expire.setUTCSeconds(1);

    if (!eventViewed && (!!this.isEventAccessEnabled || !!this.isEventCreator) && this.userId) {
      const expireSeconds = (+expire - +curDate) / 1000;

      CookiesHelper.createCookie({
        name: eventViewCookieName,
        value: this.eventId,
        maxAge: expireSeconds,
      });

      Statistics.eventViewStat({
        eventId: this.eventId,
        browser: UtilsHelper.getBrowserWithVersion(),
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        contactId: this.contactId,
        userId: this.userId,
        url: window.location.href
      }, beforeRouteEnter);
    }

  }

  @Watch('conferenceRooms', { immediate: false })
  private async onConferenceRoomsChange(): Promise<void> {
    this.$nextTick(() => {
      // this.unpinActiveProgramStreams();
    });
  }
}

