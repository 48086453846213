


import { Vue, Component, Prop, Watch, Ref } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { TContact } from '@/_types/contact.type';
import { TFile } from '@/_types/file.type';
import ChatHelper from '@/_modules/chat/helpers/chat.helper';
import { TNote } from '@/_types/store/note.type';
import Person from '@/_modules/contacts/components/person/person.vue';
import iconUnFavorite from '@/_modules/icons/components/icon-ew-unfavorite.vue';
import iconFavorite from '@/_modules/icons/components/icon-ew-favorite.vue';
import iconShare from '@/_modules/icons/components/icon-ew-share.vue';
import IconDownloadableFile from '@/_modules/icons/components/icon-downloadable-file.vue';
import IconViewableFile from '@/_modules/icons/components/icon-viewable-file.vue';
import _cloneDeep from 'lodash.clonedeep';
import FileHelper from '@/_helpers/file.helper';
import {TOpenEwSharerPayload} from '@/_store/ew-sharer.store';
import DateTimeHelper from '@/_helpers/date-time.helper';

type TSharerMethods = {
  showSharer: () => void;
}

@Component({
  components: {
    Person,
    iconUnFavorite,
    iconFavorite,
    iconShare,
    IconDownloadableFile,
    IconViewableFile,
  }
})
export default class ContactTabContentProfile extends Vue {

  @Ref('announcementSharer') announcementSharer: any;
  @Getter('noteStore/noteListByContactId') readonly noteListByContactId: (contactId: number) => TNote[];
  @Action('noteStore/callNoteListAll') requestAnnouncements: (payload: {event_id: number; user_id: number}) => void;
  @Action('noteStore/addFav') setAnnouncementFavorite: (announcementId: number) => void;
  @Action('noteStore/removeFav') unsetAnnouncementFavorite: (announcementId: number) => void;
  @Action('ewSharerStore/closeSharer') closeSharer: () => void;
  @Action('ewSharerStore/openSharer') openSharer: (payload: TOpenEwSharerPayload) => void;
  @Getter('ewSharerStore/isSharerVisible') isSharerVisible: boolean;

  @Prop({type: Object, default: null})
  private readonly contact: TContact;

  public announcementList: TNote[] = [];

  @Watch('contact.user_id', {immediate: true})
  private async onContactUserIdChange(newVal: number): Promise<void> {
    if (!newVal) {
      return;
    }
    await this.requestAnnouncements({
      event_id: this.eventId,
      user_id: this.contact.user_id,
    });
    this.announcementList = _cloneDeep(this.noteListByContactId(this.contact.id));
  }

  public get eventId(): number {
    return (this.$route.params.eventId && parseInt(this.$route.params.eventId, 10)) || null;
  }

  public get contactDescription(): string {
    if (!this.contact) {
      return '';
    }
    return this.linkifyUrls(this.contact.company_description || '');
  }

  public get joinAt(): string {
    if (!this.contact) {
      return null;
    }
    return DateTimeHelper.newsPublicationDate(this.contact.first_login_date);
  }

  public get contactFiles(): TFile[] {
    if (!this.contact) {
      return [];
    }
    return this.contact.files || [];
  }

  public linkifyUrls(inputString: string): string {
    return ChatHelper.createLinks(inputString);
  }

  public getAnnouncementDate(announcement: TNote): string {
    return this.$moment(announcement.updated_at).format('DD MMM YYYY HH:mm');
  }

  public toggleFavoriteAnnouncement(announcement: TNote): void {
    if (announcement.is_favorite) {
      this.unsetAnnouncementFavorite(announcement.id);
    } else {
      this.setAnnouncementFavorite(announcement.id);
    }

    const localAnnouncement: TNote = this.announcementList.find(item => item.id === announcement.id);
    if (localAnnouncement) {
      localAnnouncement.is_favorite = !localAnnouncement.is_favorite;
    }

  }

  public shareAnnouncement(event: PointerEvent): void {
    if (this.isSharerVisible) {
      this.closeSharer();
      return;
    }
    this.openSharer({
      eventTarget: event.target as Element,
      url: this.shareUrl
    });
  }

  public get shareUrl(): string {
    return location.origin + this.$router.resolve({
      name: 'notes',
      params: {
        eventId: '' + this.eventId,
      }
    }).href;
  }

  public isFileViewable(file: TFile): boolean {
    return FileHelper.isFileViewableByFilename(file.filename || '');
  }

}
